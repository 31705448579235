body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #ffffff;

  

}

.Header {
  font-size: 2em;
  color: #ffffff;
  background-color: #05304a;
  padding: 20px;
}
.Headline {
  font-size: 1.05em;
  margin-top: -30px;
}

.InputH2 {
  margin-bottom: -10px;
}
.InputH2Results {
  margin: 5px;
}

.buttonsBox {
  margin: auto;
 max-width: 30%;
 text-align: left;
 margin-top: 10px;
}





textarea {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 10px;
  padding: 20px;
  max-width: 80%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input {
  margin: 10px 0;
}

h3 {
  margin: 3px 0 90vh;
}

.button {

  width: 32%;
  border-width: 1px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;

  padding: 10px;
  background-color: #05304a;


}
.button:hover {
  background-color: #17699c;

}

.button:active {
  background-color: #2599e0;
  text-shadow: #fff 1px 0 10px;;
}

.footer {
  background-color: #05304a;
  padding: 20px;
  margin-top: 30px;
  color: #ffffff;
}


.largeFont {
  font-size: 1.5em;
}

a {
  color: #2599e0;
  text-decoration: none;

}

a:hover {
  color: #6dbff3;

}